
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: 'w-select', })
export default class SelectComponent extends Vue {
  @Prop() public value?: string;
  @Prop(Boolean) public disabled: Boolean;
	@Prop(Boolean) public plain: Boolean;

  changeValue(ev: any) {
    if(!this.disabled) {
      this.$emit('input', ev.target.value)
    }
  }
}
