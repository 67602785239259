






import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: 'w-info' })
export default class Header extends Vue {
  @Prop() public label?: string;
  @Prop() public value?: string;
}
