





	import { Component, Vue, Prop } from 'vue-property-decorator'
	@Component({
		name: 'w-image'
	})
 	export default class WImage extends Vue {
 		@Prop() src!: string
 		@Prop() alt!: string
 		private showLoading: boolean = true
 	}
