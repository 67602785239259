


















import { Component, Vue, Prop, Inject } from 'vue-property-decorator'
import EventBus from '@/utils/eventBus'
@Component({ name: 'w-navbar-item' })
export default class Navbar extends Vue {
	@Prop() type!: string
	@Prop({ default: false}) horizontal!: boolean
	@Prop() label!: string
	@Prop() exact!: boolean
	@Prop() to!: { name: string, params: any }
	@Inject('navbar') navBar: any

	get componentTag (): string {
		return this.to ? 'router-link' : 'div'
	}

	triggerEvent () {
		if (this.to) {
			this.$router.push(this.to)
		}
		EventBus.$emit('@@NAV_ITEM_CLICK', this._uid)
	}
}
