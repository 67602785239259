import {
  getModule,
  Module,
  MutationAction,
  Action,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import store from '@/store';
import axios from '@/plugins/axios'
import { IDomainSetting } from '../types'

@Module({
  namespaced: true,
  name: 'domainSetting'
})
export default class DomainSetting extends VuexModule implements IDomainSetting {
	public domainSetting: any = {}
	public count: number = 0
	@MutationAction({
		mutate: ['domainSetting']
	})
	public async fetchDomainSetting (projectId: string): Promise<any> {
		const { data } = await axios.post('/wechat/mp/domain', {action: "get"}, {headers: {'x-project-id': projectId}})
		return { domainSetting: data }
	}

	@MutationAction({
		mutate: ['domainSetting']
	})
	public 	async submitDomainSetting (payload: any): Promise<any> {
		const {params, projectId} = payload
		const { data } = await axios.post('/wechat/mp/domain', {action: "set", ...params}, {headers: {'x-project-id': projectId}})
		return { domainSetting: data }
	}

}