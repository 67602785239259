import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/main.css'
import './assets/fonts/Inter/inter.css'
import './plugins/ui'
import './plugins/vee-validate'
import i18n from './plugins/vue-i18n'
import axios from './plugins/axios'
import GlobalToast from './plugins/wtc-toast'
import GlobalModal from './plugins/wtc-modal'
import ClickOutside from './directives/click-outside'
import Loading from './directives/w-loading'
import './plugins/v-tooltip'
import CollapseTransition from './transitions/collapse-transition'
import VBodyScrollLock from 'v-body-scroll-lock'
import {SimpleSVG} from 'vue-simple-svg'
const filter = require('@/filters')
const cookie = require('vue-cookie')
Vue.config.productionTip = false
Vue.use(cookie)
Vue.use(GlobalToast, {decuration: 2000})
Vue.use(VBodyScrollLock)
Vue.use(GlobalModal)
Vue.use(require('vue-moment'))
Vue.directive('click-outside', ClickOutside)
Vue.directive('w-loading', Loading)
Vue.component('w-transition', CollapseTransition)
Vue.prototype.$axios = axios


  // store.dispatch('context/setAppLoading', true)
function checkAuth() {
  return new Promise(async (resolve, reject) => {
    const token = cookie.get('superadmin-jwt') || cookie.get('account-manager-jwt')
    if (token && (typeof token !== 'undefined') && token != 'undefined') {
      try {
        if(cookie.get('superadmin-jwt')) {
          await store.dispatch('auth/getSuperadminData', cookie.get('superadmin-jwt'))
        } else if (cookie.get('account-manager-jwt')) {
          await store.dispatch('auth/getAccountManagerData', cookie.get('account-manager-jwt'))
        }
        await store.dispatch('fetchMiniPrograms', {page: 1, limit: 1000, keepFailed: true})
        // await store.dispatch('thirdPartyAppManagement/fetchQuotaAndSpeedUp')
        resolve(true)
      } catch (err) {
        console.log("User profile - Error", err)
        // router.push("/logout")
        // await store.dispatch('users/logout')
        reject(err)
      }
    } else {
      // router.push("/login")
      resolve(true)
    }
  })
}

function createApp() {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

// if(cookie.get('superadmin-jwt') || cookie.get('account-manager-jwt')) {
// 
// } else {
//   router.push('/login')
// }


checkAuth().then(async () => {
  createApp();
}).catch(err => {
  createApp();
  console.log("err", err)
}).finally(() => {
  // store.dispatch('context/setAppLoading', false)
})
