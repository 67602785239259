import Vue from 'vue'
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('secret', {
	validate: (value: string) => value === 'example',
	message: 'What are u fucking doing?'
})

extend('required', {
	...required,
	message: (params: any) => {
		return `${params} field is required`
	}
})

// 多个参数的情况

// extend('demo', {
// 	params: ['min', 'max'],
// 	validate: (value, params: { min: any, max: any }) => {
// 		debugger
// 	}
// })
extend('email', email)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)