var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-tag overflow-hidden inline-flex rounded text-center ring-1 ring-white",class:[
    _vm.tagClass,
    {
      'bg-gray-300': _vm.type == 'default', 
      'bg-yellow-200 text-yellow-800': _vm.warnings.includes(_vm.type) || _vm.warning, 
      'bg-gray-100 text-gray-600': _vm.noactions.includes(_vm.type), 
      'bg-green-100 text-green-800': _vm.successes.includes(_vm.type),
      'bg-red-100 text-red-600': _vm.danger.includes(_vm.type)
    },
    { 'h-5 px-2 text-xs': _vm.small, 'h-7 px-2 text-sm': !_vm.small },
    _vm.roundedFull ? 'rounded-full' : 'rounded'
  ]},[_c('div',{staticClass:"flex justify-between items-center h-full font-medium"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }