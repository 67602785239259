
import { Component, Vue } from 'vue-property-decorator'
import { VNode, CreateElement } from 'vue'
import GlobalToast from '@/plugins/wtc-toast'
@Component({
	name: 'w-toast'
})
export default class Toast extends Vue {

	created () {
		GlobalToast.eventBus.$on('show', this.showToast)
	}

	showToast () {
		console.log('showToast')
	}

	render (h: CreateElement): VNode {
		const dataObj = {
			staticClass: 'w-full h-16 bg-teal-500'
		}
		return h('div', dataObj,'I am toast')
	}
}

