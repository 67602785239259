import {
  getModule,
  Module,
  MutationAction,
  Action,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import store from '@/store';
import axios from '@/plugins/axios'
import { IMiniAppItem } from '@/types/common.d.ts'
interface IDashboard {
	qrcodes: Array<object>
	fetchQrcodes: (params: {limit: number, skip: number}) => Promise<any>
}

@Module
class DashboardModule extends VuexModule implements IDashboard {
	public qrcodes: Array<object> = []
	public count: number = 0

	@Mutation
	FETCH_QR_CODES (payload: any) {
		this.qrcodes = payload.data
		this.count = 0
	}

	@Action({ rawError: true })
	public async fetchQrcodes (params: {limit: number, skip: number}): Promise<any> {
		const filter = {
			limit: params.limit,
      skip: params.skip,
      fields: {
        info: false
      }
		}
		const response: any = await axios.get(`/wechat/mps`, {params: { filter }})
		let data = []
		if (response.data) {
			data = response.data.filter((item: IMiniAppItem) => item.qrCodeUrl).map((item: IMiniAppItem) => {
				return {qrCodeUrl: item.qrCodeUrl, shopName: item.project.shopName}
			})
		}
		this.context.commit('FETCH_QR_CODES', { data, count: response.headers['x-total-count'] })
		return { data: response.data, count: response.headers['x-total-count'] }
	}
}

export default DashboardModule