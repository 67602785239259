




















import { Component, Vue, Prop, Inject } from 'vue-property-decorator'
@Component({ name: 'w-navbar-sub-item' })
export default class NavbarSubItem extends Vue {
	@Prop() type!: string
	@Prop({ default: false}) horizontal!: boolean
	@Prop() label!: string
	@Prop() to!: { name: string, params: any }
	@Inject('navbar') navBar: any

	showSubmenu () {
		if (this.navBar.subMenuId == this._uid) {
			this.navBar.subMenuId = ''
		} else {
			this.navBar.subMenuId = this._uid
		}
	}
}
