
import { VNode, CreateElement } from 'vue'
const defaultOpts = {
	trigger: "hover",
	delay: "700",
	autoHide: true,
	offset: "3"
}
export default {
	name: 'w-tooltip',
	functional: true,
	render (h: CreateElement, context: any) {
		const { props, slots, data } = context
		const options = Object.assign({}, defaultOpts, data.attrs)
		return h('v-popover', {
			props: options}, [slots().default, h('div', {slot: 'popover'}, props.message)])
	}
}
