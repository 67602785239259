import { DirectiveOptions } from 'vue'
const context = '@clickOutside'
const ClickOutside: DirectiveOptions = {
	bind: function (el: any, binding, vnode) {
		const handler = function($event: Event) {
			if (el.contains($event.target)) return false
			if (binding.expression) {
				binding.value()
			}
			// console.log('document click')
		}
		el[context] = handler
		document.addEventListener('click', handler)
	},
	inserted: function () {
		console.log()
	},
	unbind: function (el: any) {
		document.removeEventListener('click', el[context])
		delete el[context]
	}
}
export default ClickOutside