import Vue from 'vue'

const requireComponents = require.context('@/components/common/ui', true, /\.vue$/)
requireComponents.keys().forEach(componentPath => {
	const componentConfig = requireComponents(componentPath)
	let componentName: any = null
	if (componentConfig.default) {
		componentName = componentConfig.default.extendOptions ? componentConfig.default.extendOptions.name : componentConfig.default.name
	} else {
		componentName = componentConfig.extendOptions ? componentConfig.extendOptions.name : componentConfig.name
	}
	Vue.component(componentName, componentConfig.default || componentConfig)
})
