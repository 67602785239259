import {
  getModule,
  Module,
  MutationAction,
  Action,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import store from '@/store';
import axios from '@/plugins/axios'
import { ITemplate } from '../types'
@Module
class TemplatesModule extends VuexModule implements ITemplate {
	public drafts:Array<any> = []
	public templates:Array<any> = []

	@Mutation
	public FETCH_DRAFTS (payload: any) {
		this.drafts = payload
	}

	@Mutation
	public FETCH_TEMPLATES (payload: any) {
		this.templates = payload
	}

	@Action({ rawError: true })
	public async fetchDrafts (): Promise<any> {
		const { draft_list } = await axios.$get('/wechat/mp/code/drafts')
		draft_list.sort((a: any, b: any) => {
			return b.create_time - a.create_time
		})
		this.context.commit('FETCH_DRAFTS', draft_list)
		return { drafts: draft_list }
	}

	@Action({ rawError: true })
	public async fetchTemplates (): Promise<any> {
		const {template_list} = await axios.$get('/wechat/mp/code/templates')
		template_list.sort((a: any, b: any) => {
			return b.template_id - a.template_id
		})

		this.context.commit('FETCH_TEMPLATES', template_list.slice(0, 10))
		return { templates: template_list }
	}

	@Action({ rawError: true })
	public async addToTemplates (draftId: string): Promise<any> {
		const data = await axios.post(`/wechat/mp/code/drafts/${draftId}/templates`)
		return Promise.resolve(true)
	}

	@Action({ rawError: true })
	public async deleteTemplate (templateId: string): Promise<any> {
		const data = await axios.delete(`wechat/mp/code/templates/${templateId}`)
		return Promise.resolve(true)
	}
}

export default TemplatesModule