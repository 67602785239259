<template>
  <div 
    :class="[
      tagClass,
      {
        'bg-gray-300': type == 'default', 
        'bg-yellow-200 text-yellow-800': warnings.includes(type) || warning, 
        'bg-gray-100 text-gray-600': noactions.includes(type), 
        'bg-green-100 text-green-800': successes.includes(type),
        'bg-red-100 text-red-600': danger.includes(type)
      },
      { 'h-5 px-2 text-xs': small, 'h-7 px-2 text-sm': !small },
      roundedFull ? 'rounded-full' : 'rounded'
    ]"
    class="w-tag overflow-hidden inline-flex rounded text-center ring-1 ring-white">
    <div class="flex justify-between items-center h-full font-medium">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'w-tag',
  props: {
    closable: {
      type: Boolean
    },
    type: {
      type: String,
      default: 'default'
    },
    small: Boolean,
    warning: Boolean,
    tagClass: {},
    roundedFull: Boolean
  },
  data() {
    return {
      warnings: ['warning','failed','1'],
      successes: ['success', '0'],
      noactions: ['no','approving', '2','3'],
      danger: ['blocked', 'danger']
    }
  },
  computed: {
    isClosable() {
      return this.closable
    }
  },
  methods: {
    handleClose(event) {
      event.stopPropagation();
      this.$emit('close', event);
    }
  }
}
</script>
<style scoped>
.w-tag+.w-tag {
  margin-left: 4px;
}
</style>