<script>
import Vue from 'vue'

export default Vue.extend({
  functional: true,
  name: 'w-spinner',
	render (h, { props }) {
	 	const attrs = {
	 		src: props.size == 'large' ? 
	 			'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDQgNDQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjU0MiAxLjQ4N0EyMS41MDcgMjEuNTA3IDAgMDAuNSAyMmMwIDExLjg3NCA5LjYyNiAyMS41IDIxLjUgMjEuNSA5Ljg0NyAwIDE4LjM2NC02LjY3NSAyMC44MDktMTYuMDcyYTEuNSAxLjUgMCAwMC0yLjkwNC0uNzU2QzM3LjgwMyAzNC43NTUgMzAuNDczIDQwLjUgMjIgNDAuNSAxMS43ODMgNDAuNSAzLjUgMzIuMjE3IDMuNSAyMmMwLTguMTM3IDUuMy0xNS4yNDcgMTIuOTQyLTE3LjY1YTEuNSAxLjUgMCAxMC0uOS0yLjg2M3oiIGZpbGw9IiM5MTlFQUIiLz48L3N2Zz4K' : 
	 			'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuMjI5IDEuMTczYTkuMjUgOS4yNSAwIDEwMTEuNjU1IDExLjQxMiAxLjI1IDEuMjUgMCAxMC0yLjQtLjY5OCA2Ljc1IDYuNzUgMCAxMS04LjUwNi04LjMyOSAxLjI1IDEuMjUgMCAxMC0uNzUtMi4zODV6IiBmaWxsPSIjOTE5RUFCIi8+PC9zdmc+Cg==',
	 		alt: 'Loading...',
	 		draggable: false
	 	}
	 	const className = [{
	 		'w-4 h-4 spinner': props.size != 'large',
	 		'w-16 h-16 spinner': props.size == 'large'
	 	}, {
	 		'grey-spinner': props.color != 'white',
	 		'white-spinner': props.color == 'white'
	 	}]
	 	return h('img', { class: className, attrs })
	}
})
</script>
<style>
	@keyframes spinner-loading{
		to{transform:rotate(1turn)}
	}
	.spinner{animation:spinner-loading .5s linear infinite; color:transparent}
	.white-spinner {
		-webkit-filter: brightness(0) saturate(100%) invert(100%);
		filter: brightness(0) saturate(100%) invert(100%);
	}
	.grey-spinner {
		-webkit-filter: brightness(1.5) saturate(0.5);
		filter: brightness(1.5) saturate(0.5);
	}
</style>