
























	import { VNode, CreateElement, VNodeData } from 'vue'
	import { Component, Vue, Prop, Provide, Watch } from 'vue-property-decorator'
	@Component({
		name: 'w-tab'
	})
	export default class WTab extends Vue {
		@Prop() value!: {}
		@Prop() rebuildAttr!: string
    @Prop() stickToLeft: Boolean
		
		private sharedState: any = {
			currentSelectedIndex: null
		}

		private tabButtons: any = []

		@Watch('value', {
			immediate: true
		})
		currentChange (val: string) {
			this.sharedState.currentSelectedIndex = val
			this.$emit('onChange', this.sharedState.currentSelectedIndex)
		}

		@Watch('rebuildAttr')
		rebuildAttrChange (val: any) {
			this.tabButtons = this.buildTabs()
		}

		@Provide('tab') tab: any = {
			sharedState: this.sharedState,
			tabInstance: this
		}

		buildTabs () {
			return this.$slots.default.map((pane: any) => {
				let button = pane.componentOptions.propsData
				return button
			})
		}

		created () {
			this.tabButtons = this.buildTabs()
		}

		selectTab (button: any) {
			if(!button.to) {
				this.$router.push({query: {current: String(button.name)}})
				this.sharedState.currentSelectedIndex = button.name
			}
			this.$emit('onChange', button.name)
		}
	}
