import Vue from 'vue'
import Modal from '@/components/common/ui/w-modal.vue'

const ModalConstructor = Vue.extend(Modal)
let instance: any = null
const WTCModal = function(options: any) {
	instance = new ModalConstructor()
	instance.content = options.content
	instance.header = options.header
	instance.visible = true
	if (options.onCertify) {
		instance.$on('onCertify', function () {
			options.onCertify()
			WTCModal.Close()
		})
	}
	instance.$mount()
	instance.$on('onCancel', WTCModal.Close)
	document.body.appendChild(instance.$el);
}

WTCModal.Close = function () {
	// instance.$off('onCertify')
	instance.visible = false
}

const globalModal = {
	install: function ( Vue: any, options: any) {
		Vue.prototype.$modal = WTCModal
	}
}

export default globalModal