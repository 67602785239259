















	import { Component, Vue, Prop } from 'vue-property-decorator'
	@Component({
		name: 'w-xcard'
	})
 	export default class WCard extends Vue {
 		@Prop() header!: string
 		@Prop({default: true}) hoverEffect!: boolean 
 	}
