<template>
  <div class="pagination w-full">
    <div class="flex items-center justify-between sm:flex-wrap">
      <div class="sm:mb-1">
        <template v-if="limit">
          Showing <span class="font-medium">{{ limit * (currentPage - 1) + 1 }}</span> to <span class="font-medium">{{ (limit * currentPage) > total ? total : (limit * currentPage) }}</span>
          <span v-if="total"> of <span class="font-medium">{{ total }}</span> result{{ total > 1 ? 's' : '' }}</span>
        </template>
      </div>
      <div class="flex">
        <div class="flex rounded bg-white text-gray-600 h-11 border font-medium justify-between">
          <div class="flex" >
            <button @click="clickPage(currentPage-1)"
              class="flex items-center justify-center w-10 mx-0 cursor-pointer border-r focus:outline-none hover:text-gray-900"
              :disabled="currentPage == 1" :class="{'text-gray-300': currentPage == 1}">
              <svg class="w-3 h-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"/></svg>
            </button>
            <template v-for="p in totalPages">
              <button 
                v-if="isVisible(p)"
                :key="p"
                @click="clickPage(p)"
                class="flex items-center justify-center font-medium w-10 mx-0 cursor-pointer border-r focus:outline-none hover:text-gray-900"
                :class="{'text-cerulean-500': currentPage == p}">
                {{ p }}
              </button>
              <button v-else-if="p == 2 || p == totalPages-1" :key="p" class="flex items-center justify-center h-11 w-10 mx-0 cursor-pointer border-r focus:outline-none">...</button>
            </template>
            <button @click="clickPage(currentPage+1)" class="flex items-center justify-center w-10 mx-0 cursor-pointer focus:outline-none hover:text-gray-900" :disabled="currentPage == totalPages" :class="{'text-gray-300': currentPage == totalPages}">
              <svg class="w-3 h-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'w-pagination',
  props: {
    limit: {
      type: Number,
      default: 10
    },
    total: {
      required: true
    },
    currentPage: {
      type: [Number, String],
      defaut: 1
    },
    addQueryParams: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      inputPage: '',
      sortBy: 'created',
      sortDirection: 'DESC'
    }
  },
  computed: {
    totalPages() {
      if(this.total) {
        return Math.ceil(this.total / this.limit)
      }
    }
  },
  methods: {
    clickPage(page) {
      let queryx = Object.assign({}, this.$route.query, { page: page })
      this.$router.push({query: queryx});
      this.$emit('onChange', page )
    },
    isVisible(page) {
      let minimum = (this.currentPage - 3)
      let maximum = this.currentPage + 3

      if(minimum < 0) {
        maximum += -minimum
      }
      if(maximum > this.totalPages) {
        minimum -= (maximum - this.totalPages)
      }

      let ok = page > minimum && page < maximum
      ok = ok || (page == 1 || page == this.totalPages)
      
      return ok
    }
  }
}

//   data () {
//     return {
//       page: 1,
//       sortBy: 'created',
//       sortDirection: 'DESC'
//     }
//   },
//   computed: {
//     sortOrder() {
//       return `${this.sortBy} ${this.sortDirection}`
//     }
//   },
//   mounted() {
//     this.page = this.$route.query.page || 1
//   },
//   methods: {
//     changePage ({page, addQueryParams}) {
//       if (page < 1) return false
//       this.tableLoading = true;
//       this.page = page
// 
//       if(addQueryParams) {
//         let queryx = Object.assign({}, this.$route.query, { page: this.page })
//         this.$router.push({query: queryx});
//       }
// 
//       this.fetchObjects()
// 
//       setTimeout(() => {
//         window.scrollTo(0,0)
//       }, 300)
//     },
//     prevPage (page) {
//       this.tableLoading = true;
//       // this.page = page - 1
//       // this.changePage(this.page)
//     },
//     nextPage (page) {
//       this.tableLoading = true;
//       // this.page = page + 1
//     },
//     changeSort({ column, prop, order }) {
// 
//       console.log("## changeSort column", column)
//       console.log("## changeSort prop", prop)
//       console.log("## changeSort order", order)
// 
//       this.sortBy = prop
//       this.sortDirection = (order == 'ascending' || order == 'ASC') ? 'ASC' : 'DESC'
//       this.fetchObjects()
//     }
//   }

</script>
<style lang="less">
.pagination {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>