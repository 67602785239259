















	import { Component, Vue, Prop, Provide } from 'vue-property-decorator'
	import {ValidationObserver} from 'vee-validate'
	type $refs = {
    observer: InstanceType<typeof ValidationObserver>;
  };

	@Component({
		name: 'w-form'
	})
	export default class WForm extends Vue {
		@Prop() title!: string
		@Prop() rules!: object
		@Prop() model!: object
		@Provide('form') form: WForm = this 

		validate () {
			return (this.$refs as $refs).observer.validate()
		}
	}
