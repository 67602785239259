























































	import { Component, Vue, Prop } from 'vue-property-decorator'
	import { IBreadcrumb } from '@/types/common'
	import { State } from 'vuex-class'
	@Component({
		name: 'w-dropdown',
	})
	export default class Dropdown extends Vue {
		// @State(state => state.breadcrumb) breadcrumb: any
    @Prop({ type: Boolean }) noBorder: boolean;
    @Prop({ default: 'Options' }) label: string;
    @Prop() options: []
    @Prop() groups: []

    private opened: boolean = false

    toggleMenu() {
      this.opened = !this.opened
    }

    get formattedGroups() {
      if(!this.groups || this.groups.length == 0) {
        return [this.options]
      } else {
        return this.groups
      }
    }

	}
