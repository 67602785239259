var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.file)?_c('div',_vm._g({staticClass:"fill-current w-icon items-center inline-flex",class:[
    {'w-1 h-1': _vm.size == '1'},
    {'w-2 h-2': _vm.size == '2'},
    {'w-3 h-3': _vm.size == '3'},
    {'w-4 h-4': _vm.size == '4'},
    {'w-5 h-5': _vm.size == '5'},
    {'w-6 h-6': _vm.size == '6'},
    {'w-7 h-7': _vm.size == '7'},
    {'w-8 h-8': _vm.size == '8'},
    {'w-10 h-10': _vm.size == '10'},
    {'w-16 h-16': _vm.size == '16'}
  ]},_vm.$listeners),[_c('w-svg-icon',{staticClass:"w-full",attrs:{"src":("/img/icons/" + _vm.file + ".svg"),"fill":_vm.fillColor,"width":"100%","height":"100%"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }