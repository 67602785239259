import {
  getModule,
  Module,
  MutationAction,
  Action,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import store from '@/store';
import axios from '@/plugins/axios'
import { IMPFeatures } from '../types'


@Module
export default class MPFeatures extends VuexModule implements IMPFeatures {

	public QRCode: any = null

	@Mutation
  GENERATE_QR_CODE(qrcode: any) {
  	this.QRCode = qrcode
  }

	@Action({ rawError: true })
	public async generateQRCode (params: { path: string, projectId: string }): Promise<any> {
		const data = await axios.$get(`/wechat/mp/qrcode?type=${'releasedA'}&path=${params.path}`, {headers: {'x-project-id': params.projectId}})
		this.context.commit('GENERATE_QR_CODE', data)
		return { QRCode: data }
	}

	@Action({ rawError: true })
	public async uploadQRCode (params: {file: any, projectId: string}): Promise<any> {
		const data: any = await axios.post('/wechat/mp/qrcode/upload', params.file, {headers: {'x-project-id': params.projectId}})
		return {data: data}
	}
}