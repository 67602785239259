





	import { Component, Vue, Prop } from 'vue-property-decorator'
	@Component({
		name: 'w-xswitch'
	})
 	export default class WSwitch extends Vue {
 		@Prop({type: Boolean}) value!: boolean

 		toggleValue () {
 			this.$emit('input', !this.value)
 		}

 	}
