import {
  getModule,
  Module,
  MutationAction,
  Action,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import { Auth } from '../types'
import store from '@/store';
import axios from '@/plugins/axios'
const cookie = require('vue-cookie')

@Module({
  namespaced: true,
  name: 'auth'
})
class AuthModule extends VuexModule {
  public user: Auth|null = null
  public isAuthenticated: boolean = !!(cookie.get('superadmin-jwt') || cookie.get('account-manager-jwt'))
  public token = cookie.get('superadmin-jwt') || cookie.get('account-manager-jwt')
  public tokenType = cookie.get('superadmin-jwt') ? 'SuperAdmin' : 'AccountManager'
  @MutationAction
  public async login ({password = '', email = ''}): Promise<any> {
  	const res: { data: Auth } = await axios.post(`/super-admins/login`, {password, email})
  	let payload: any = {
  		user: res.data,
      isAuthenticated: true,
      token: res.data.token,
      tokenType: 'SuperAdmin'
  	}
  	cookie.set(`superadmin-jwt`, res.data.token, 1)
  	return payload
  }

  @MutationAction
  public logout (): Promise<any> {
    cookie.delete('superadmin-jwt', {domain: ''})
    return Promise.resolve({
      user: null,
      token: '',
      isAuthenticated: false,
      tokenType: null
    })
  }
  @MutationAction
  public async accountManagerLogin ({password = '', email = ''}): Promise<any> {
    const res: { data: Auth } = await axios.post('/account-managers/login?include=user', { email, password })
    cookie.delete('superadmin-jwt', {domain: ''})
    cookie.set('account-manager-jwt', res.data.token, 1)
    let payload: any = {
      user: res.data,
      isAuthenticated: true,
      token: res.data.token,
      tokenType: res.data.type
    }
    return payload
  }

  @MutationAction
  async getSuperadminData(token: string): Promise<any> {
    try {
      let data = {}
      if (token) {
        data = await axios.$get(`/super-admins/me`, {headers: {'x-access-token': token}})
      } else {
        data = await axios.$get('/super-admins/me')
      }

      return Promise.resolve({ user: data, isAuthenticated: true, tokenType: 'SuperAdmin', token: token })
    } catch (error) {
      return Promise.reject(error);
    }
  }

  @MutationAction
  async getAccountManagerData(token: string): Promise<any> {
    try {
      let data = {}
      if (token) {
        data = await axios.$get(`/account-managers/me`, {headers: {'x-access-token': token}})
      } else {
        data = await axios.$get('/account-managers/me')
      }
      return Promise.resolve({ user: data, isAuthenticated: true, tokenType: 'SuperAdmin', token: token })
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // getters
  get isLoggedin () {
    return this.isAuthenticated
  }
}

export default AuthModule	
