






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { VNode, CreateElement } from 'vue'
import { addClass } from '@/utils/dom'
@Component({
	name: 'w-modal'
})
export default class Modal extends Vue {
	@Prop() visible!: boolean
	@Prop() header!: string
	@Prop() content!: string

	private mask: any = null

	@Watch('visible')
	visibleChange (val: boolean) {
	  if (val) {
	  	const mask: any = document.createElement('div')
	  	mask['@@clickHandler'] = (event: any) => {
	  		this.$emit('onCancel')
	  	}
	  	mask['@@escClose'] = (event: any) => {
	  		if (event.keyCode == 27) {
	  			this.$emit('onCancel')
	  		}
	  	}
	  	mask.addEventListener('click', mask['@@clickHandler'])
	  	document.addEventListener('keydown', mask['@@escClose'])
	  	addClass(mask, 'inset-0 bg-black opacity-50 fixed z-40')
	  	this.mask = mask
	    document.body.appendChild(this.mask);
	  } else {
	  	if (this.mask) {
	  		document.removeEventListener('keydown', this.mask['@@escClose'])
	  		this.mask.addEventListener('click', this.mask['@@clickHandler'])
	  		document.body.removeChild(this.mask);
	  		this.mask = null
	  	}
	  }
	}
	beforeDestroy () {
		if (this.mask) {
			document.removeEventListener('keydown', this.mask['@@escClose'])
			document.body.removeChild(this.mask);
		}
	}

	mounted () {
	}
}
