







	import { Component, Vue, Prop } from 'vue-property-decorator'
	@Component({
		name: 'w-loading'
	})
 	export default class WLoading extends Vue {
 	}
