



















import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
	name: 'w-icon'
})
export default class Icon extends Vue {
	@Prop({ default: 5 }) size!: number|string
	@Prop() file!: string
	@Prop({ default: 'solid' }) type!: string
	@Prop() fillColor!: string
}
