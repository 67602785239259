





import { Component, Vue } from "vue-property-decorator";

@Component({ name: 'w-form-buttons' })
export default class FormButtons extends Vue {
}
