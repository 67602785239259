import Vue, { DirectiveOptions, VNode } from 'vue'
const context = '@@imageLoading'
const loadingClass="w-full absolute inset-0 flex items-center justify-center"
let ImageLoading: DirectiveOptions = {
	//在bind里面是访问不到parentElement的,因为Vue的Dom插入顺序是先子后父，这个时候el还没有插入到父元素呢
	bind: function (el: any, binding, vnode: VNode) {
		if (binding.modifiers.image) {
			el[context] = {
				handler: function ($event: Event) {
					let parentElement = ($event!.target as HTMLElement).parentElement
					parentElement!.removeChild(($event!.target as HTMLElement).nextSibling as any)
					// (($event!.target as HTMLElement).nextSibling as HTMLElement).style.display = 'none'
				}
			}
		}
		el['mask'] = new Vue({
			el: document.createElement('div'),
			data () {
				return {hide: false}
			},
			render: function (h) {
				return h('div', {staticClass: loadingClass, style: {backgroundColor: 'hsla(0,0%,100%,.9)', display: (this as any).hide ? 'none !important' : 'block'}, attrs: {id: 'v-w-loading'}}, [h('w-spinner')])
			}
		})
	},
	inserted (el: any, binding, vnode: VNode) {
		console.log('inserted')
		el.style.position = 'relative'
		const elComputedStyle = window.getComputedStyle(el)
		if (binding.modifiers && binding.modifiers.image) {
			const imageElement = el.getElementsByTagName('img')[0]
			imageElement.parentElement.style.height = imageElement.clientHeight + 'px'
			el['mask'].$el.style.height = imageElement.clientHeight + 'px'
			imageElement.addEventListener('load', el[context].handler)
			el.appendChild(el['mask'].$el)
			return 
		} 
		if (binding.value) {
			el.appendChild(el['mask'].$el)
		}
		// vnode!.context!.$nextTick(() => {
		// 	vnode.elm && vnode.elm.parentElement && vnode.elm.parentElement.append(divElement)
		// })
		
	},
	update (el: any, binding, vnode: VNode) {
		el['mask'].hide = !binding.value
	},
	unbind: function (el: any) {
		console.log('unbind')
		if (el[context]) {
			document.removeEventListener('load', el[context])
			delete el[context]
		}
		if (el.mask) {
			el.mask.$destroy()
		}
	}

}

export default ImageLoading