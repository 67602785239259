import {
  getModule,
  Module,
  MutationAction,
  Action,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import store from '@/store';
import axios from '@/plugins/axios'
import { IThirdPartAppManagement } from '../types'
@Module({
	namespaced: true,
	name: 'thirdPartyAppManagement'
})
class ThirdPartAppManagementModule extends VuexModule implements IThirdPartAppManagement {

  public information: any = {}

	@MutationAction({
		mutate: ['information']
	})
	public async fetchQuotaAndSpeedUp (): Promise<any> {
		const data  = await axios.$get('/wechat/mp/code/quota')
		return { information: data }
	}
}

export default ThirdPartAppManagementModule