














import { Component, Vue, Prop } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'

@Component({
  name: 'w-language-switch'
})
export default class WLanguageSwitch extends Vue {
  @State(state => state.i18n.locale) locales: string
  @State(state => state.i18n.locale) locale: string

  @Action('setLang', { namespace: 'i18n' }) setLang: any;

  toggleLang () {
    let newLocale = this.locale == 'en' ? 'cn' : 'en'
    this.$i18n.locale = newLocale
    this.setLang(newLocale)
  }
}
