
  import { Component, Vue, Prop, Inject } from 'vue-property-decorator'
  import { VNode, CreateElement, VNodeData } from 'vue'
  type RuleInterface = {
  	name: string
  	validate: () => boolean
  	message: (param: any) => string | string
  }
  @Component({
  	name: 'w-form-field'
  })
	export default class FormField extends Vue {
		@Prop(String) prop!: string
		@Prop() label!: string
		@Prop({type: Boolean}) horizontal!: boolean
		@Prop() customCss!: any
		@Inject('form') form!: any

		// mounted () {
		// 	(this.$refs.validationProvider as Vue & {setErrors: Function}).setErrors('sdsds')
		// }

		private render (h: CreateElement): VNode {
			const labelNode = this.$slots.label ? this.$slots.label : h('div', {staticClass: 'w-form-field__label flex-1 py-2 text-gray-500', class: { 'hidden': !this.label }}, this.label)
			const validationProvider:any = h('ValidationProvider', {
				props: {
					rules: this.form.rules ? this.form.rules[this.prop] : '',
					name: this.prop,
					mode: 'eager'
				},
				staticClass: 'w-full flex-1',
				ref: 'validationProvider',
				scopedSlots: {
					default: (props) => {
						return h('div', [this.$slots.default, h('div', {
							staticClass: 'text-red-600'
						}, props.errors)])
					}
				}
			})
			const content: VNode = h('div', {
				staticClass: `w-form-field flex w-full py-2 ${this.horizontal ? 'flex items-baseline text-right' : 'flex-col'}`,
				style: this.customCss
			}, [ labelNode, validationProvider ])
			return content
		}
	}
