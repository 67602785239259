





















import { Component, Vue, Prop, Provide } from 'vue-property-decorator'
import EventBus from '@/utils/eventBus'
@Component({ name: 'w-navbar' })
export default class Navbar extends Vue {
	@Prop() type!: String
	@Prop({ default: false}) horizontal!: boolean

	private showMenu: boolean = false

	subMenuId: string = ''

	sharedState: any = {
		selectedItemId: ''
	}

	@Provide('navbar') private navbarInstance:any = this.sharedState

	created () {
		EventBus.$on('@@NAV_ITEM_CLICK', (itemId: string) => {
			this.sharedState.selectedItemId = itemId
		})
	}

	beforeDestroy () {
		EventBus.$off('@@NAV_ITEM_CLICK')
	}

	private toggleMenu (): void {
		this.showMenu = !this.showMenu
	}
}
