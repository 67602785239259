











import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
@Component({
	name: 'w-xpagination'
})
export default class WPagination extends Vue {
	@Prop({ type: Number, required: true }) total!: number
	@Prop({ type: Number, required: true }) per_page!: number
	@Prop({ type: String }) layout!: string
	private currentPage: number = 1

	tapItem (type: string|number) {
		switch (type) {
			case 'prevPage':
				if (!this.prevPageEnable) {
					return false
				}
				this.currentPage--
				break
			case 'nextPage': 
				if (!this.nextPageEnable) {
					return false
				}
				this.currentPage++
				break
			default: 
				this.currentPage = Number(type)
		}

		let queryx = Object.assign({}, this.$route.query, { page: this.currentPage })
		this.$router.push({query: queryx});

		// this.$router.push({query: {page: String(this.currentPage)}})
		this.$emit('onChange', (this.currentPage - 1) * this.per_page)
	}
	
	get prevPageEnable () {
		return this.currentPage > 1
	}

	get nextPageEnable () {
		return this.currentPage < this.totalPage
	}

	get totalPage () {
		return Math.ceil(this.total / this.per_page)
	}

	get _total (): Array<string>|number {
		if (this.totalPage > 10) {
			return [...Array(5).keys()].map(i => i + 1 + '').concat(['...', String(this.totalPage)])
		} else {
			return this.totalPage
		}
	}

	mounted () {
		if (this.$route.query.page) {
			this.currentPage = Number(this.$route.query.page)
			this.$emit('onChange', (this.currentPage - 1) * this.per_page)
		}
	}
}
