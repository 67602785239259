










import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
@Component({
	name: 'w-panel'
})
export default class Select extends Vue {
	@Prop() title!: string
}
