
import { Component, Vue, Prop } from 'vue-property-decorator'
import { VNode, CreateElement } from 'vue'
@Component({
	name: 'w-table-col' 
})
export default class TableCol extends Vue {
	@Prop({ required: true }) label!: string
	@Prop({ required: true }) prop!: string
	render (h: CreateElement) {
		return h()
		// return h('td', {
		// 		staticClass: 'w-table-col border-c px-4 py-2',
		// 	}, [this.$scopedSlots && this.$scopedSlots.default ? this.$scopedSlots.default({
		// 						text: 'dsdsd'
		// 					}) : this.label])
	}
}
