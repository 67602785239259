import Vue from 'vue'
import { toastObject } from '@/utils/toastObject'
const shortId = require('shortid')
const TOAST_CLASS = ['text-base', 'text-white', 'px-6', 'py-4', 'rounded-lg', 'fixed', 'shadow-md', 'z-30']
const TYPE_COLOR = {
  'info': 'bg-indigo-400',
  'error': 'bg-red-600',
  'warning': 'bg-yellow-500'
}
interface IToast {
  container: any
  globalOptions: any
  remove: (el: HTMLElement) => HTMLElement
  toasts: Array<any>
  show: (message: string, options: any) => void
  _initiateToastContainer: (instance: any) => void
}


export class Toast implements IToast {
	container: any = null
	id: string
  toasts: any | null = []
  globalOptions: any = {
    label: ''
  }
  removeTimer: any = null
	constructor (options: any) {
    this.globalOptions = options
    this.id = shortId.generate()
		this._initiateToastContainer(this)
	}

  success (message: any, options: any) {
    // clone globalOptions
    const _globalOptions = Object.assign({}, this.globalOptions)
    // merge show options and globalOptions
    const _options = Object.assign(_globalOptions, options)
    if (!_options.label) {
      _options.label = 'Success!'
    }
    const styles = [...TOAST_CLASS, 'bg-gray-800']
    this._show(message, _options, styles)
  }

  error (message: any, options: any) {
    // clone globalOptions
    const _globalOptions = Object.assign({}, this.globalOptions)
    // merge show options and globalOptions
    const _options = Object.assign(_globalOptions, options)
    if (!_options.label) {
      _options.label = 'Wooops!'
    }
    const styles = [...TOAST_CLASS, 'bg-red-400']
    this._show(message, _options, styles)
  }

  warning (message: any, options: any) {
    // clone globalOptions
    const _globalOptions = Object.assign({}, this.globalOptions)
    // merge show options and globalOptions
    const _options = Object.assign(_globalOptions, options)
    if (!_options.label) {
      _options.label = 'Warning!'
    }
    const styles = [...TOAST_CLASS, 'bg-yellow-400']
    this._show(message, _options, styles)
  }

  show (message: any, options: any) {
    // clone globalOptions
    const _globalOptions = Object.assign({}, this.globalOptions)
    // merge show options and globalOptions
    const _options = Object.assign(_globalOptions, options)
    const styles = [...TOAST_CLASS, 'bg-cerulean-500']
    this._show(message, options, styles)
  }

  _show ( message: any, options: any, styles: any ) {
  	if (message) {
      let newToast = document.createElement('div')
      // let toastHeader = document.createElement('div')
      let toastContent = document.createElement('div')
      // toastHeader.id = 'toast_header'
      // toastHeader.innerHTML = options.label
      // toastHeader.classList.add('text-left','font-semibold')
      // newToast.appendChild(toastHeader)
      newToast.appendChild(toastContent)
  		toastContent.innerHTML = message
      toastContent.classList.add('text-base', 'overflow-hidden')
  		newToast.classList.add(...styles)
      newToast.setAttribute('hash', shortId.generate())
  		this.container.appendChild(newToast)
      newToast.animate([
      {
        opacity: '0',
        transform: 'translateY(20px)'
      },
      {
        opacity: '1',
        transform: 'translateY(0px)'
      }
      ], {duration: 300})
      newToast.style.cssText = "transition: all 0.5s ease; right: 20px;min-width: 200px; max-width: 350px;display: -webkit-box; -webkit-line-clamp: 2; text-overflow: ellipsis; -webkit-box-orient: vertical;"
      let verticalOffset = 0
      this.toasts.forEach((item: any) => {
        verticalOffset+=item.el.offsetHeight + 16
      })
      verticalOffset+=16
      newToast.style.top = verticalOffset + 'px'
      this.toasts.push(toastObject(newToast, this))
      this.removeTimer = setTimeout(() => {
        this.remove(newToast)
      }, options.decuration || 2500)
  	}
  }

  remove (el: HTMLElement) {
    this.removeTimer = null
    let removeVertical = el.offsetHeight, index = -1
    let toasts = this.toasts.filter((item: any, i: number) => {
      if (item.el.getAttribute('hash') !== el.getAttribute('hash')) {
        return true
      } else {
        index = i
      }
    })
    for (let i = index; i < toasts.length; i++) {
      toasts[i].el.style.top = parseInt(toasts[i].el.style.top, 10) - removeVertical - 16 + 'px'
    }
    if (el.parentNode)  el.parentNode.removeChild(el)
    this.toasts = toasts
    return el
  }

  close () {

  }

  _initiateToastContainer (instance: any) {
  	if (!window) return
  	let _instance = instance
  	let container = document.createElement('div'), body = document.body
  	// container.id = instance.id
  	// container.classList.add('fixed')
   //  container.style.right = '20px'
   //  container.style.top = '10%'
  	// body.appendChild(container)
  	instance.container = body
  }
}

const GlobalToast: any = {

	install: function ( Vue: any, options: any) {
		Vue.prototype.$toast = new Toast(options)
	}
}
export default GlobalToast