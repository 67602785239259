





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: 'w-card-section'
})
export default class Card extends Vue {
  @Prop() public title?: string;
  @Prop(Boolean) public expand: Boolean;
  @Prop(Boolean) public sub: Boolean;
}
