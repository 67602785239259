







import { VNode, CreateElement, VNodeData } from 'vue'
import { Component, Vue, Prop, Inject } from 'vue-property-decorator'
@Component({
	name: 'w-tab-pane'
})
export default class WTabPane extends Vue {

	@Prop() label!: string
	@Prop() name!: string
	@Prop() to!: object
	@Prop() disabled!: Boolean

	get uid () {
		return this._uid
	}
	get index () {
		if (this.tab && this.tab.tabInstance.$slots.default) {
			return this.tab.tabInstance.$slots.default.findIndex((item: any) => {
				return item.componentInstance.uid === this._uid
			})
		}
	}

	@Inject('tab') tab!: any
}
