
import { VNode, CreateElement, VNodeData } from 'vue'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'


@Component({
	name: 'w-table' 
})
export default class Table extends Vue {
	@Prop({ required: true}) data!: any[]
	@Prop() selectRow!: boolean
	@Prop() noBottomBorder!: boolean
	@Prop() rebuildAttr: any
	@Prop() loading!: boolean
	@Prop() valign: string// = "middle"

	@Watch('rebuildAttr')
	rebuildAttrChange (val: any) {
		this.columns = this.buildColumns()
	}

	private columns!: any[]

	public getChildrenTextContent (): void {

	}
	private normalRenderCell (h: CreateElement, params: { attrs: any, data: any }): VNode {
		// this._v 就是用于生成TextVnode的，等同于：
		// new VNode(undefined, undefined, undefined, String(params.data))
		return (this as any)._v(params.data)
	}

	public genTableCol (columns: any[], row: any): VNode[] {
		return columns.map(({ prop, attrs , scopedSlots, slots }) => {
			let children = null
			
			if (scopedSlots && scopedSlots.default) {
				children = scopedSlots.default(row)
			} else {
				children = this.normalRenderCell.call(Vue.prototype._renderProxy, this.$createElement, { prop, attrs, scopedSlots, slots, data: row[prop]})
			}
			return this.$createElement('td', { attrs: {...attrs, valign: this.valign}, staticClass: 'px-4 py-3' }, [children])
			//overflow-hidden break-normal truncate
		})
	}

	public genTableRow (): VNode[] {
		return this.data.map(item => {
			return this.$createElement('tr', {staticClass: 'border-b last:border-0 hover:bg-gray-50', class: {'cursor-pointer': this.selectRow}, on: {click: () => {this.$emit('tapRow', item)}}}, 
				this.genTableCol(this.columns, item))
		})
	}

	public genTableHeader (): VNode[] {
		return this.columns.map((column: any) => {
			return this.$createElement('th', {attrs: column.attrs, staticClass: 'px-4 h-14 font-medium'}, column.label)
		})
	}

	public buildColumns (): any[] {
		return this.$slots && this.$slots.default && this.$slots.default.map((node: VNode) => {
			const { prop, label } = node.componentOptions!.propsData as any
			const data: VNodeData = node.data!
			return {
				prop,
				label,
				attrs: data!.attrs,
				scopedSlots: data!.scopedSlots,
				slot: data!.slot
			}
		}) || []
	}

	created () {
		this.columns = this.buildColumns()
	}

	render (h: CreateElement) {
		const tableRow: VNode[] = this.genTableRow()
		const tableHeader: VNode[] = this.genTableHeader()
		let tableVnodeData = {
			staticClass: 'w-table table-fixed overflow-hidden rounded w-full'
		}
		let tableHeader_class: string = "py-6 px-2 text-left text-gray-500 rounded-t border-b"
		const tableNode: VNode = h('table', tableVnodeData, [h('thead', {staticClass: tableHeader_class}, tableHeader), h('tbody', { class: {'opacity-25': this.loading} }, tableRow)])
		return h('div', {staticClass: 'w-table_wrapper relative w-full', style: { 'min-height': 'auto' }}, [
				h('div', {staticClass: 'mt-2 h-10 w-full absolute px-4 transition-all duration-300 ease-in-out', class: {'opacity-0 -z-1': !this.loading}}, [h('div', { staticClass: 'bg-cerulean-100 text-cerulean-600 rounded flex gap-2 items-center px-4 text-white h-full w-full' }, [
					h('w-loader', { staticClass: 'text-cerulean-600 w-4' }),
					'Loading...'
				]
				)]),
				tableNode
			])
	}
}
