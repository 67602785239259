import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
const cookie = require('vue-cookie')
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: cookie.get('lang_miniapp_management') || 'cn',
  messages: {
    en: require('@/locales/en.json'),
    cn: require('@/locales/cn.json')
  }
})
export default i18n