import {
  getModule,
  Module,
  MutationAction,
  Action,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import store from '@/store';
import axios from '@/plugins/axios';
import { IMiniAppItem } from '@/types/common.d.ts';
import MiniProgram from '@/models/MiniProgram';
@Module({
  namespaced: false
})
class DeploymentModule extends VuexModule {
	// Vuex 里面的属性只能是public
	public mpList: any = []
	public miniProgram!: IMiniAppItem
	public miniProgramCount: number = 0
	public auditFailedList: any = []
	public uploadFailedList: any = []
	public failedAuditCount: number = 0

	@Mutation
	FETCH_MINI_PROGRAMS (payload: any) {
		this.mpList = payload.data
		this.miniProgramCount = Number(payload.count)
	}

	@Mutation
	FETCH_MINI_PROGRAM (miniProgram: any) {
		this.miniProgram = miniProgram
	}

	@Mutation
	UPLOAD_CODE_FOR_ALL (payload: any) {
		this.uploadFailedList = payload
	}

	@Mutation
	UPDATE_VISIT_STATUS (payload: any) {
		this.miniProgram.created = payload
	}

	@Mutation
	APPEND_DEPLOY_HISTORY (params: any) {
		this.mpList!.forEach((item: IMiniAppItem) => {
			if (item.projectId === params.projectId) {
				item!.deploys!.unshift(params.deployHistory)
			}
		})
	}

	@Mutation
	UPDATE_AUDIT_STATUS (payload: any) {
		const newStatus = payload.reduce((obj: any, item: any) => {
			obj[item.projectId] = {...item.result, auditId: item.auditId}
			return obj
		}, {})
		this.mpList!.forEach((item: IMiniAppItem) => {
			const deploy = item.deploys.find((item: any) => item.auditInfo && item.auditInfo.auditId == newStatus[item.projectId].auditId)
			if (deploy) {
				deploy.auditInfo.status = newStatus[item.projectId].status
			}
		})
	}

	@Mutation
	SET_FAILED_AUDIT_COUNT (payload: number) {
		this.failedAuditCount = payload
	}

	@Action({ rawError: true})
	public async fetchMiniPrograms (params: {limit: number, page: number, query: string, keepFailed: boolean}): Promise<{items: MiniProgram[], count: number}> {
		const filter: any = {
			include: [{
				relation: 'deploys',
				scope: {
					order: 'created DESC',
					limit: 10,
					skip: 0,
					where: {
						'auditInfo.status': {
							gt: -1
						}
					}
				}
			}],
			limit: params.limit,
      skip: (params.page-1)*params.limit,
			where: {
				and: [{}]
			}
		}
		if (params.query) {
			filter['customWhere'] = {
				shopName: params.query
			}
		}

		if(params.keepFailed) {
			filter['where']['and'].push({
				'deployInfo.auditInfo.status': 1
			})
		}

		const response: { data: MiniProgram[], headers: any } = await axios.get(`/wechat/mps`, {params: { filter }})
		
		if (response.data && params.keepFailed) {
			response.data = response.data.filter((mp: any) => {
				return mp.deploys.filter((item: any) => item.auditInfo.status == 1 ).length > 0
			})
			this.context.commit('SET_FAILED_AUDIT_COUNT', response.data.length)
		}
		this.context.commit('FETCH_MINI_PROGRAMS', {data: response.data.map(item => new MiniProgram(item)), count: response.headers['x-total-count']})
		return { items: response.data, count: response.headers['x-total-count'] }
	}

	@Action({ rawError: true})
	public async fetchMiniProgram (id: string): Promise<any> {
		try {
			const filter = {
				include: [{
					relation: 'deploys',
					scope: {
						order: 'created DESC',
						limit: 10,
						skip: 0,
						where: {
							'auditInfo.status': {
								gt: -1
							}
						}
					}
				}]
			}
			const miniProgram: any = await axios.$get(`/wechat/mps/${id}`, {params: { filter }})
			// miniProgram.deploys = miniProgram.deploys.filter((item: any) => item.auditInfo.auditId)
			this.context.commit('FETCH_MINI_PROGRAM', new MiniProgram(miniProgram))
			return Promise.resolve(new MiniProgram(miniProgram))
		} catch (err) {
			return Promise.reject(err)
		}
	}

	@Action({ rawError: true})
	public async uploadMiniappCode ({ templateId = '', userVersion = '', userDesc = '', projectId = ''}): Promise<any> {
		const params = {
      "templateId": String(templateId),
      "userVersion": userVersion || '1.0.0',
      "userDesc": userDesc || 'First commit',
      "piwikSiteId": process.env.VUE_APP_SERVER == 'production' ? '10' : '11',
      "funDebug": process.env.VUE_APP_SERVER == 'production' ? 'production' : 'development'
		}
		await axios.post('/wechat/mp/code/commit', params, {headers: {'x-project-id': projectId}})
		return Promise.resolve(true)
	}

	@Action({ rawError: true})
	public async submitMiniappAudit (projectId: string): Promise<any> {
		const {data} = await axios.post('/wechat/mp/code/audit', {}, {headers: {'x-project-id': projectId}})
		if (data.errcode !== 0) {
			return Promise.reject(data)
		}
		return data
	}

	@Action({ rawError: true})
	public async releaseMiniapp (projectId: string): Promise<any> {
		const { data } = await axios.post('/wechat/mp/code/release', {}, {headers: {'x-project-id': projectId}})
		if (data.errcode !== 0) {
			return Promise.reject(data)
		}
		return Promise.resolve(true)
	}

	@Action({ rawError: true})
	public async rollBackMiniapp (projectId: string): Promise<any> {
		await axios.post('/wechat/mp/code/rollback', {}, {headers: {'x-project-id': projectId}})
		return Promise.resolve(true)
	}

	@Action({ rawError: true})
	public async undoAudit (projectId: string): Promise<any> {
		const { data } = await axios.post('/wechat/mp/code/undo-audit', {}, {headers: {'x-project-id': projectId}})
		return data
	}

	@Action({ rawError: true})
	public async uploadCodeForAll ({ templateId = '', userVersion = '', userDesc = '', piwikSiteId = '', funDebug = '', projectId = ''}): Promise<any> {
		const params = {
      "templateId": String(templateId),
      "userVersion": userVersion || '1.0.0',
      "userDesc": userDesc || 'First commit',
      "piwikSiteId": String(piwikSiteId),
      "funDebug": funDebug
		}
		const { data } = await axios.post('/wechat/mp/code/commit-for-all', params)
		const failedList = data.filter((item: any) => item && item.result.errcode != 0)
		this.context.commit('UPLOAD_CODE_FOR_ALL', failedList)
		return {uploadFailedList: failedList}
	}

	@Action({ rawError: true})
	public async appendDeployHistory (params: {deployHistory: any, projectId: string}) {
		this.context.commit('APPEND_DEPLOY_HISTORY', params)
	}

	@Action({ rawError: true})
	public async submitCodeForAll (): Promise<any> {
		await axios.post('/wechat/mp/code/audit-for-all')
		return true
	}

	@Action({ rawError: true})
	public async updateVisitStatus (params: {status: string, projectId: string}): Promise<any> {
		this.context.commit('UPDATE_VISIT_STATUS', params.status == 'close' ? 1 : 2)
		const data = await axios.patch('/wechat/mp/visit-status', {action: params.status}, {headers: {'x-project-id': params.projectId}})
		return data
	}

	@Action({ rawError: true})
	public async updateSearchStatus (params: {status: number, projectId: string}): Promise<any> {
		const { data } = await axios.patch('/wechat/mp/privacy-setting', {status: params.status}, {headers: {'x-project-id': params.projectId}})
		return data
	}

	@Action({ rawError: true})
	public async experienceQR (projectId: string): Promise<any> {
		const data = await axios.$get('/wechat/mp/qrcode?type=testing', {headers: {'x-project-id': projectId}})
		return data
	}

	@Action({ rawError: true})
	public async manuallyRefreshAuditStatus (projectIds: Array<string>) {
		const data = await axios.$get(`/wechat/mp/code/bulk-audit-status`, {params: {projectIds: projectIds}})
		this.context.commit('UPDATE_AUDIT_STATUS', data)
		return data
	}
}

export default DeploymentModule	