


















import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'w-switch'
})
export default class WSwitch extends Vue {
  @Prop({type: Boolean}) value!: boolean
  @Prop() label!: string
  @Prop({type: Boolean}) disabled!: boolean

  toggle () {
    if(!this.disabled) {
      let newValue = !this.value
      this.$emit('input', newValue)
      this.$emit('change', newValue)
    }
  }
}
