import Vue from "vue";
import moment from 'moment' 

export default class MiniProgram {

  public releaseDate: Date;
  public deploys: any;

  constructor(params: any) {
    Object.assign(this, params)
  }


  getCurrentTemplate() {
    if(this.deploys && this.deploys.length > 0) {
      if(this.releaseDate > this.deploys[0].submitDate && this.deploys[0].auditInfo.status == 0 ) {
        return this.deploys[0]
      } else if( this.deploys.length > 1) {
        let currentTemplate: any = null
        let index = 1
        try {
          do {
            currentTemplate = this.deploys[index] && this.deploys[index].auditInfo && this.deploys[index].auditInfo.status == 0 ? this.deploys[index] : null
            index++
          } while (!currentTemplate && index < this.deploys.length);
        } catch (err) {
          console.log(err)
        }
        return currentTemplate
      }
    }
    return null
  }

  getCurrentTemplateId() {
    let current = this.getCurrentTemplate()
    return (current && current.codeInfo) ? current.codeInfo.templateId : null
  }

  getCurrentTemplateDescription() {
    let current = this.getCurrentTemplate()
    return (current && current.codeInfo) ? current.codeInfo.userDescription : null
  }

  getReleaseDate() {
    if(this.releaseDate) {
      return moment(this.releaseDate).format("YYYY-MM-DD HH:mm:ss")
    }
  }

  getLastDeploy() {
    let deploys = this.deploys
    return deploys[0]
  }

}