import {
  getModule,
  Module,
  MutationAction,
  Action,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import store from '@/store';
import axios from '@/plugins/axios'
import { IMember } from '../types'
const cookie = require('vue-cookie')

@Module({namespaced: true})
export default class I18n extends VuexModule {
	public locales: Array<any> = [
		{label: 'CN', value: 'cn'},
		{label: 'EN', value: 'en'}
	]

	public locale: string = cookie.get('lang_miniapp_management') || 'cn'

	@Mutation
	SET_LANG (lang: string) {
		this.locale = lang
		cookie.set(`lang_miniapp_management`, lang)
	}

	@Action({ rawError: true })
	public async setLang (lang: string): Promise<any> {
		this.context.commit('SET_LANG', lang)
		return Promise.resolve(lang)
	}
}