import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@/store/module/auth'
import MiniProgramsModule from '@/store/module/miniPrograms'
import DashboardModule from '@/store/module/dashboard'
import DomainSettingModule from '@/store/module/domainSetting'
import MemberModule from '@/store/module/member'
import TemplatesModule from '@/store/module/templates'
import MPFeaturesModule from '@/store/module/miniProgramFeatures'
import ThirdPartyAppManagementModule from '@/store/module/thirdPartyAppManagement'
import LanguageModule from '@/store/module/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
    breadcrumb: [{label: 'MP Management'}, {label: 'Mini-Program '}]
  },
  mutations: {
    UPDATE_BREADCRUMB (state, payload) {
      state.breadcrumb = payload
    }
  },
  actions: {
    updateBreadcrumb ({ commit }, breadcrumb) {
      commit('UPDATE_BREADCRUMB', breadcrumb)
    }
  },
  modules: {
  	auth: Auth,
  	deployment: MiniProgramsModule,
  	dashboard: DashboardModule,
    domainSetting: DomainSettingModule,
    member: MemberModule,
    templates: TemplatesModule,
    mpFeatures: MPFeaturesModule,
    thirdPartyAppManagement: ThirdPartyAppManagementModule,
    i18n: LanguageModule
  }
})
