import {
  getModule,
  Module,
  MutationAction,
  Action,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import store from '@/store';
import axios from '@/plugins/axios'
import { IMember } from '../types'

@Module
export default class MemberManagement extends VuexModule implements IMember {
	public members: Array<any> = []

	@Mutation
	FETCH_MEMBERS (payload: any) {
		this.members = payload
	}

	@Action({ rawError: true })
	public async fetchMembers (projectId: string): Promise<any> {
		const data = await axios.$get('/wechat/mp/members', {headers: {'x-project-id': projectId}})
		this.context.commit('FETCH_MEMBERS', data)
		return { members: data }
	}

	@Action({ rawError: true })
	public async addMember ({wechatId = '', projectId = ''}): Promise<any> {
		const data = await axios.post('/wechat/mp/members', { wechatId }, {headers: {'x-project-id': projectId}})
		return true
	}

}