import Vue from 'vue'

Vue.filter('verifyType', function (value: string|number) {
	if (value === undefined || value === null) return ''
	return value == -1 ? '未认证' : '微信认证'
})

Vue.filter('auditStatus', function (value: string|number) {
	if (value === undefined || value === null) return ''
	switch (value) {
		case 0:
			return 'Success'
		case 1: 
			return 'Failed'
		case 2:
			return 'Approving'
		case 3:
			return 'Undo'
		default: 
			return '-'
	}
})