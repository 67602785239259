import axios from 'axios'
import store from '../store'
import router from '../router'
// axios 配置
const request = axios.create({
	timeout: 15000,
  baseURL: process.env.VUE_APP_API_URL,
})

request.$get = function () {
  return new Promise((resolve: any, reject: any) => {
    request.get(arguments[0], arguments[1]).then((res: any) => {
      resolve(res ? res.data : null) 
    }).catch(err => {
      reject(err)
    })
  })
}

// http request 拦截器
request.interceptors.request.use(
  config => {
    if (store.getters['auth/isLoggedin']) {
      config.headers.common['x-access-token'] = store.state.auth.token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
)

// http response 拦截器
request.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      router.replace({name: 'auth-logout'})
    }
    return Promise.reject(error.response && error.response.data)
  },
)

export default request