









import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'w-loader'
})
export default class Loader extends Vue {
  // @Prop() color: string = 'text-white'
}
