









import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: 'w-badge' })
export default class Header extends Vue {
  @Prop({ type: Boolean }) warning: boolean;
}
