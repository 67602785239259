


















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: 'w-button'
})
export default class Button extends Vue {
  @Prop() public label?: string;
  @Prop({ default: 'button' }) public type: string;
  @Prop(Boolean) public primary: Boolean;
  @Prop(Boolean) public destructive: Boolean;
  @Prop(Boolean) public plain: Boolean;
  @Prop(Boolean) public loading: Boolean;
  @Prop(Boolean) public disabled: Boolean;
  @Prop() public to?: [Object, String];
	@Prop() public tooltip?: string;
  
  get tag() {
    if(this.disabled) {
      return 'button'
    }
    else if(this.to) {
      return 'router-link'
    } else {
      return 'button'
    } 
  }
}
